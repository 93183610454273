  // 履历弹出
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="处理履历"
      class="handleResume"
    >
      <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
        height:550
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="true"
      :is-caculate-height="false"
      @pageIndexChange='pageIndexChange'
      @pageSizeChange='pageSizeChange'
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </base-table>
     <template slot="footer">
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
    </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { resumeData } from '../utils/dialog-config'
import { lockAPi } from '@/api/assetApi'

export default {
  props: {
    visible: Boolean,
    contractId: String
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        contractId: ''
      },
      loadCount: 0,
      tableData: []
    }
  },
  components: { BaseDialog, BaseButton, BaseTable },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    columns () {
      return resumeData(this)
    },
    api () {
      return lockAPi
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.queryParas.contractId = this.contractId
          this.getData()
        })
      }
    }
  },
  methods: {
    // 获取分页数据
    getData () {
      lockAPi.lockHistory(this.queryParas).then(res => {
        this.tableData = res.data.records
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.tableData.total = res.data.total
          })
        })
      })
    },
    pageIndexChange (index) {
      console.log('111')
      this.queryParas.pageIndex = index
      this.getData()
    },
    pageSizeChange (size) {
      this.queryParas.pageIndex = 1
      this.queryParas.pageSize = size
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
  .addSginer{
    margin-top: 200px;
    height: 500px;
  }

  .tips{
    padding: 16px;
    font-size: 16px;
    color: #EE1C1C;
  }
</style>
